<template>
  <v-form
    id="forgotten-password-form"
    ref="form"
    @submit.prevent="handleSubmit"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="form.email"
            prepend-icon="$account"
            validate-on-blur
            outlined
            color="primary"
            :label="$trans('login_email')"
            :rules="[rules.required, rules.email]"
            :disabled="loading"
            hide-details="auto"
          />
        </v-col>
        <v-col v-if="error" cols="12">
          <v-alert type="error">
            {{ error }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            color="primary"
            type="submit"
            form="forgotten-password-form"
            height="56px"
            depressed
            :disabled="loading"
            :loading="loading"
          >
            {{ $trans("forgotten_password_button") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import { required, email } from "@/lib/calendesk-js-library/forms/validators";
import dialogTypes from "../dialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";

export default {
  name: "ForgottenPasswordDialogForm",
  mixins: [sharedActions],
  data: () => ({
    form: {
      email: null,
    },
    loading: false,
    error: null,
    rules: {
      required,
      email,
    },
  }),
  created() {
    if (this.getDialog.data && this.getDialog.data.email) {
      this.form.email = this.getDialog.data.email;
    }

    if (this.getDialog.data && this.getDialog.data.selectedCompany) {
      this.loading = true;
      this.$emit("loading", true);
      this.sendEmailWithLinkChangePassword();
    }

    if (!this.isUserLogged) {
      return;
    }

    this.logout();
  },
  methods: {
    ...mapActions({
      logout: "session/logoutUserAndDestroySession",
      resetForgottenPassword: "auth/resetForgottenPassword",
      getTenant: "setup/getTenant",
      setTenantData: "setup/setTenantData",
    }),
    handleSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.$emit("loading", true);
      this.getTenant(this.form.email)
        .then(({ data }) => {
          const { length } = data;
          if (length === 1) {
            const tenant = data[0];
            this.setTenantData(tenant);
            this.sendEmailWithLinkChangePassword();
          } else if (length > 1) {
            // Don't move it to finally, it gets destroyed before sending loading event
            this.$emit("loading", false);
            this.loading = false;

            this.openDialog({
              type: dialogTypes.CHOOSE_TENANT,
              title: this.$trans(
                "choose_tenant_dialog_forgotten_password_title",
              ),
              size: dialogSize.MIDDLE,
              data: {
                type: "forgottenPassword",
                email: this.form.email,
                companies: [...data],
              },
            });
          } else {
            // Don't move it to finally, it gets destroyed before sending loading event
            this.$emit("loading", false);
            this.loading = false;

            this.openDialog({
              type: dialogTypes.CONFIRM_SEND_MAIL,
              title: this.$trans("forgotten_password_success_title"),
            });
          }
        })
        .catch(() => {
          this.error = this.$trans("error_occurred");
          this.loading = true;
          this.$emit("loading", false);
        });
    },
    sendEmailWithLinkChangePassword() {
      this.resetForgottenPassword(this.form.email)
        .then(() => {
          // Don't move it to finally, it gets destroyed before sending loading event
          this.$emit("loading", false);
          this.loading = false;
          this.openDialog({
            type: dialogTypes.CONFIRM_SEND_MAIL,
            title: this.$trans("forgotten_password_success_title"),
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("loading", false);

          if (error?.response?.data?.code === "TOO_MANY_REQUESTS") {
            this.error = this.$trans("too_many_requests");
          } else if (
            error?.response?.data?.code === "TENANT_UNDER_MAINTENANCE"
          ) {
            this.error = this.$trans("tenant_account_frozen_error");
          } else {
            this.error = this.$trans("error_occurred");
          }
        });
    },
  },
};
</script>
